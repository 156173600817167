import { createGlobalStyle } from 'styled-components'
import { Colors } from '../utils/colors'

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&display=swap');

  @font-face {
    font-family: 'Source Sans Pro';
    src: url(fonts/SourceSansPro-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url(fonts/SourceSansPro-Black.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  html, body {
    scroll-behavior: smooth;

  }

  body, div {
    -webkit-font-smoothing: antialiased;
  }


  *, body, input, button, textarea, span, h1, h2, h3, h4, h5, h6 {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    font-family: 'Source Sans Pro', sans-serif;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
    outline: 0px;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  html,
  body {
    background-color: #fafafc;
  }

  input[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

export const Theme = () => {
  return {
    colors: {
      ...Colors
    }
  }
}
