import React from 'react'
import { ThemeProvider } from 'styled-components'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Theme, GlobalStyle } from './src/styles/global'
import unRegisterWorkers from './src/functions/unRegisterWorker'

import 'firebase/database'

import { Helmet } from 'react-helmet'

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <base href="/" />
      </Helmet>

      <GlobalStyle />

      <ThemeProvider theme={Theme}>
        {element}
      </ThemeProvider>
    </>
  )
}

unRegisterWorkers()

// export function onServiceWorkerUpdateReady() {
//     window.location.reload()
// }

// exports.onCreateWebpackConfig = ({ stage, loaders, actions }) => {
//   if (stage === "build-html" || stage === "develop-html") {
//     actions.setWebpackConfig({
//       module: {
//         rules: [
//           {
//             test: /algoliasearch/,
//             use: loaders.null(),
//           },
//         ],
//       },
//     })
//   }
// }
