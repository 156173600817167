export enum Colors {
    purple = "#571D5D",
    red = "#DF1D3D",
    light_info = "#9DCAFE",
    info = "#1D4570",
    light_warning = "#FDDEA4",
    dark_warning = "#965307",
    background_gray = "#F0F1F7",
    white = "#fff",
    gray = "#7C8195",
    grayWords = "#6B6B84",
    black = "#25272D",
    success = "#4fce5d",
    warn = "#FEB647",
}
